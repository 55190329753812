import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "super-admin-panel-nav-menu" }
const _hoisted_2 = { class: "super-admin-panel-nav-menu__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/super-admin/school-list",
        class: "super-admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("School list")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/super-admin/school-admin-list",
        class: "super-admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("School admin list")
        ])),
        _: 1
      })
    ])
  ]))
}